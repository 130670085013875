import React, { useReducer } from 'react'

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  darkTheme: false,
  sent: false,
  posts: 3,
  submitted: false,
  lang: 'pl-PL',
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_THEME': {
      return {
        ...state,
        darkTheme: !state.darkTheme,
      }
    }
    case 'TOGGLE_SENT': {
      return {
        ...state,
        sent: true,
      }
    }

    case 'TOGGLE_POSTS': {
      return {
        ...state,
        posts: state.posts + 3,
      }
    }
    case 'GERMAN': {
      return {
        ...state,
        lang: 'de-DE',
      }
    }
    case 'ENGLISH': {
      return {
        ...state,
        lang: 'en-EN',
      }
    }
    case 'POLISH': {
      return {
        ...state,
        lang: 'pl-PL',
      }
    }
    case 'TOGGLE_LANG': {
      return {
        ...state,
        lang: window.navigator.userLanguage || window.navigator.language,
      }
    }

    default:
      throw new Error('bad action type')
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
